import React from 'react'
import styled, { keyframes } from 'styled-components'
import { promoteLayer } from './utils'

export interface FadeContentsProps {
  duration: number
  animatingOut?: boolean
  direction: 'left' | 'right' | undefined
}

const getFadeContainerKeyFrame = ({ animatingOut, direction }: FadeContentsProps) => {
  if (!direction) return
  return keyframes`
    to {
      transform: translateX(0px);
      opacity: ${animatingOut ? 0 : 1};
    }
  `
}

const FadeContainer = styled.div<FadeContentsProps>`
  ${promoteLayer}
  animation-name: ${getFadeContainerKeyFrame};
  animation-duration: ${(props) => props.duration}ms;
  animation-fill-mode: forwards;
  opacity: ${(props) => (props.direction && !props.animatingOut ? 0 : 1)};
  top: 0;
  left: 0;
`

export const FadeContents = React.forwardRef(
  (
    { children, duration, animatingOut, direction }: React.PropsWithChildren<FadeContentsProps>,
    ref
  ) => (
    <FadeContainer
      // prevent screen readers from reading out hidden content
      aria-hidden={animatingOut}
      animatingOut={animatingOut}
      direction={direction}
      duration={duration}
      ref={ref as any}
    >
      {children}
    </FadeContainer>
  )
)
