// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-learn-articles-tsx": () => import("./../../../src/pages/learn/articles.tsx" /* webpackChunkName: "component---src-pages-learn-articles-tsx" */),
  "component---src-pages-learn-changelog-tsx": () => import("./../../../src/pages/learn/changelog.tsx" /* webpackChunkName: "component---src-pages-learn-changelog-tsx" */),
  "component---src-pages-learn-tsx": () => import("./../../../src/pages/learn.tsx" /* webpackChunkName: "component---src-pages-learn-tsx" */),
  "component---src-pages-learn-tutorial-series-tsx": () => import("./../../../src/pages/learn/tutorial-series.tsx" /* webpackChunkName: "component---src-pages-learn-tutorial-series-tsx" */),
  "component---src-pages-testimonials-tsx": () => import("./../../../src/pages/testimonials.tsx" /* webpackChunkName: "component---src-pages-testimonials-tsx" */),
  "component---src-templates-kb-tsx": () => import("./../../../src/templates/kb.tsx" /* webpackChunkName: "component---src-templates-kb-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-video-series-tsx": () => import("./../../../src/templates/videoSeries.tsx" /* webpackChunkName: "component---src-templates-video-series-tsx" */)
}

