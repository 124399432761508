import React from 'react'
import IntenseLayout from './intense'
import IndexLayout from './default'

const handler = ({ children, pageContext }: any) => {
  if (pageContext?.layout === 'intense') {
    return <IntenseLayout>{children}</IntenseLayout>
  }
  return <IndexLayout>{children}</IndexLayout>
}

export default handler
