import React from 'react'
import {
  VSpaced,
  Block,
  Transition,
  FontWeight,
  ResponsiveType,
  useResponsive,
  Capital,
  BodySmall,
  Row,
  H1
} from '@thesisedu/web/dist'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { ArrowRightOutlined } from '@ant-design/icons'
import { Col } from 'antd'
import Case from 'case'
import { LessonPiecesQuery } from '../../graphqlTypes'
import { BodyLarger } from '../styled'
import { LessonPiece } from './LessonPiece'
import { useHashEffect } from '../useHashEffect'

export const query = graphql`
  query lessonPieces {
    content: allFile(
      sort: { order: [ASC], fields: [name] }
      filter: { sourceInstanceName: { eq: "lessonPieces" } }
    ) {
      edges {
        node {
          ...LessonPiece
        }
      }
    }
  }
`

export function useLessonPiecesQuery() {
  const results = useStaticQuery<LessonPiecesQuery>(query)
  return results.content.edges.map((edge) => edge.node)
}

export function LessonPiecesWidget() {
  const [selectedLessonPieceId, setSelectedLessonPieceId] = React.useState<string | undefined>(
    undefined
  )
  const lessonPieces = useLessonPiecesQuery()
  const isLarge = useResponsive(ResponsiveType.GreaterThan, 'lg')
  const containerRef = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    if (!selectedLessonPieceId) {
      setSelectedLessonPieceId(isLarge && lessonPieces.length ? lessonPieces[0].id : undefined)
    }
  }, [isLarge, lessonPieces.length])
  useHashEffect(() => {
    const lessonPiece = lessonPieces.find(
      (c) =>
        Case.kebab(c.childMarkdownRemark.frontmatter.title) ===
        window.location.hash.replace('#', '')
    )
    if (lessonPiece) {
      setSelectedLessonPieceId(lessonPiece.id)
      if (containerRef.current) {
        containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }
  })
  const headerContent = (
    <>
      <Capital color={'@primary-color'}>Methodology</Capital>
      <Block marginTop={'@size-s'}>
        <H1>What's in a Lesson?</H1>
      </Block>
    </>
  )
  const navigationContent = (
    <VSpaced space={'@size-s'} align={'stretch'}>
      {lessonPieces.map((lessonPiece) => (
        <LessonPieceItem
          key={lessonPiece.id}
          name={lessonPiece.childMarkdownRemark.frontmatter.title}
          subtitle={lessonPiece.childMarkdownRemark.frontmatter.subtitle}
          color={lessonPiece.childMarkdownRemark.frontmatter.color}
          iconPath={lessonPiece.childMarkdownRemark.frontmatter.icon.publicURL}
          onSelect={() => setSelectedLessonPieceId(lessonPiece.id)}
          isSelected={selectedLessonPieceId === lessonPiece.id}
        />
      ))}
    </VSpaced>
  )

  const selectedLessonPiece = lessonPieces.find((c) => c.id === selectedLessonPieceId)
  const mode = selectedLessonPieceId || 'no-lessonPiece'
  const viewLessonPiece = selectedLessonPiece ? (
    <LessonPiece onBack={() => setSelectedLessonPieceId(undefined)} piece={selectedLessonPiece} />
  ) : null

  if (isLarge) {
    return (
      <Container id={'lessons'} ref={containerRef}>
        <Row gutter={{ sm: 36 }}>
          <Col xs={11} xl={10}>
            {headerContent}
            {navigationContent}
          </Col>
          <Col xs={13} xl={14}>
            <Transition state={mode} type={'fade-scale'}>
              {viewLessonPiece}
            </Transition>
          </Col>
        </Row>
      </Container>
    )
  }
  return (
    <Container id={'lessonPieces'} ref={containerRef}>
      {headerContent}
      <Transition state={mode} type={'fade-scale'}>
        {viewLessonPiece || navigationContent}
      </Transition>
    </Container>
  )
}

const Container = styled.div`
  margin-top: ${(props) => props.theme['@size-section-md']};
`

interface LessonPieceItemProps {
  name: string
  onSelect: () => void
  isSelected?: boolean
  iconPath: string
  color: string
  subtitle?: string
}
function LessonPieceItem({
  isSelected,
  subtitle,
  name,
  onSelect,
  iconPath,
  color
}: LessonPieceItemProps) {
  return (
    <LessonPieceItemContainer
      color={color}
      className={isSelected ? 'selected' : ''}
      onClick={onSelect}
    >
      <div className={'left'}>
        <img src={iconPath} alt={`Icon for ${name}`} />
      </div>
      <div>
        <BodyLarger weight={FontWeight.SemiBold} style={{ lineHeight: 1 }}>
          {name}
        </BodyLarger>
        {subtitle ? <BodySmall color={'@text-color-secondary'}>{subtitle}</BodySmall> : null}
      </div>
      <span className={'arrow'}>
        <ArrowRightOutlined />
      </span>
    </LessonPieceItemContainer>
  )
}

const LessonPieceItemContainer = styled.div<{ color: string }>`
  cursor: pointer;
  transition: color 0.1s linear, background 0.1s linear;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  overflow: hidden;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme['@gray-5']};
  &,
  .left,
  img {
    border-top-left-radius: ${(props) => props.theme['@border-radius-base']};
    border-bottom-left-radius: ${(props) => props.theme['@border-radius-base']};
  }
  .left {
    align-self: stretch;
    display: flex;
    align-items: center;
    flex-direction: row;
    background: ${(props) => props.theme[props.color]};
    margin-right: ${(props) => props.theme['@size-s']};
  }
  img {
    height: ${(props) => props.theme['@size-xl']};
  }
  .arrow {
    transition: opacity 0.1s linear, transform 0.1s cubic-bezier(0.7, 0, 0.84, 0);
    transform: translateX(-15px);
    opacity: 0;
    font-size: ${(props) => props.theme['@size-m']};
    color: ${(props) => props.theme[props.color]};
    margin: 0 ${(props) => props.theme['@size-s']} 0 auto;
  }
  p {
    margin-right: ${(props) => props.theme['@size-s']};
  }
  &:hover,
  &.selected {
    background: ${(props) => props.theme['@background-color-base']};
    color: ${(props) => props.theme['@gray-7']};
    .arrow {
      transition: opacity 0.5s linear, transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
      transform: translateX(0);
      opacity: 1;
    }
  }
`
