import React from 'react'
import { media, styled } from '@thesisedu/web/dist'
import { Blob } from './Blob'
import { Container as ContentContainer } from './styled'

export function BlobHeaderBackground() {
  return (
    <Container>
      <ContentContainer>
        <TopBlob />
        <RightBlob />
      </ContentContainer>
    </Container>
  )
}

export function SubtleBlobHeaderBackground() {
  return (
    <Container>
      <ContentContainer>
        <SideBlob />
      </ContentContainer>
    </Container>
  )
}

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  height: 100vh;
  z-index: -2;
  > div {
    position: relative;
    overflow: visible;
    width: 1184px;
  }
`
const TopBlob = styled(Blob)`
  display: block;
  width: calc(100vw + 500px);
  min-width: 2000px;
  height: 400px;
  position: absolute;
  transform: rotate(-178deg);
  top: -300px;
  right: 0;
  z-index: -2;
  ${media.lg} {
    top: -250px;
  }
`
const SideBlob = styled(Blob)`
  transition: right 0.5s ease-in-out;
  display: block;
  width: calc(100vw + 500px);
  min-width: 2000px;
  height: 400px;
  position: absolute;
  transform: rotate(289deg);
  top: -120px;
  right: 510px;
  z-index: -2;
  ${media.xl} {
    right: 410px;
  }
`
const RightBlob = styled(Blob)`
  position: absolute;
  top: -825px;
  right: -1070px;
  width: 1200px;
  height: 2000px;
  transform: rotate(285deg);
  z-index: -1;
`
