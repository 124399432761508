import React from 'react'
import styled from 'styled-components'
import { media, Body, BodySmall, FontWeight } from '@thesisedu/web/dist'
import { ArrowRightOutlined } from '@ant-design/icons'
import Case from 'case'
import { Container, DropdownSection, RightArrowContainer } from './Components'
import { LessonPieceFragment } from '../../../graphqlTypes'
import { useLessonPiecesQuery } from '../../lessons/LessonsWidget'

export function LessonPiecesDropdown() {
  const lessons = useLessonPiecesQuery()
  return (
    <Container>
      <DropdownSection data-first-dropdown-section>
        {lessons.map((lesson) => (
          <LessonPiece key={lesson.id} piece={lesson} />
        ))}
      </DropdownSection>
    </Container>
  )
}

interface LessonPieceProps {
  piece: LessonPieceFragment
}
function LessonPiece({ piece }: LessonPieceProps) {
  const {
    icon,
    menu: { title, description }
  } = piece.childMarkdownRemark.frontmatter
  return (
    <LessonPieceItemContainer href={`/#${Case.kebab(piece.childMarkdownRemark.frontmatter.title)}`}>
      <div>
        <img src={icon.publicURL} alt={title} />
      </div>
      <div>
        <Body weight={FontWeight.SemiBold}>
          {title}
          <RightArrowContainer>
            <ArrowRightOutlined />
          </RightArrowContainer>
        </Body>
        <BodySmall>{description}</BodySmall>
      </div>
    </LessonPieceItemContainer>
  )
}

const LessonPieceItemContainer = styled.a`
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: calc(${(props) => props.theme['@size-m']} / 2);
  cursor: pointer;
  > div:first-child {
    margin-right: 12px;
    img {
      height: ${(props) => props.theme['@size-l']};
      border-radius: ${(props) => props.theme['@border-radius-base']};
    }
  }
  * {
    transition: color 0.25s linear;
    color: ${(props) => props.theme['@gray-5']};
  }
  &:hover {
    * {
      color: ${(props) => props.theme['@gray-7']};
    }
    ${RightArrowContainer} > * {
      left: 0;
      opacity: 100;
    }
  }
  ${media.md} {
    width: 50%;
  }
`
