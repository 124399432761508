import React from 'react'
import Granim from 'granim'
import { styled } from '@thesisedu/web/dist'
import { transparentize } from 'polished'

export function Blob(props: any) {
  const canvasRef = React.useRef<HTMLCanvasElement>(null)
  React.useEffect(() => {
    if (canvasRef.current) {
      const instance = new Granim({
        element: canvasRef.current,
        name: 'granim',
        opacity: [1, 1],
        direction: 'diagonal',
        isPausedWhenNotInView: false,
        states: {
          'default-state': {
            transitionSpeed: 10000,
            gradients: [
              [
                { color: '#12a1a1', pos: 0 },
                { color: '#0fc9c9', pos: 0.7 },
                { color: '#13dada', pos: 1 }
              ],
              [
                { color: '#12a1a1', pos: 0 },
                { color: '#0fc9c9', pos: 0.5 },
                { color: '#6bbe46', pos: 0.8 }
              ],
              [
                { color: '#12a1a1', pos: 0 },
                { color: '#0fc9c9', pos: 0.45 },
                { color: '#f89721', pos: 0.8 }
              ],
              [
                { color: '#12a1a1', pos: 0 },
                { color: '#0fc9c9', pos: 0.47 },
                { color: '#eb2627', pos: 0.8 }
              ]
            ]
          }
        }
      })
      return () => {
        instance.destroy()
      }
    }
    return undefined
  }, [])
  return <Canvas {...props} ref={canvasRef} />
}

const Canvas = styled.canvas`
  width: 800px;
  height: 800px;
  border-radius: 200px;
  box-shadow: 0 5px 50px 0 ${props => transparentize(0.75, props.theme['@primary-color'])};
  background-image: linear-gradient(226deg, #0ba1a1 5%, #009596 100%);
`
