import React from 'react'
import styled from 'styled-components'
import { FontWeight } from '@thesisedu/web/dist'

const NavbarItemTitle = styled.button`
  background: transparent;
  border: 0;
  font-size: ${(props) => props.theme['@font-size-lg']};
  color: ${(props) => props.theme['@gray-5']};
  font-weight: ${() => FontWeight.SemiBold};
  padding: ${(props) => props.theme['@size-xs']} calc(${(props) => props.theme['@size-l']} / 2);
  display: flex;
  justify-content: center;
  transition: opacity 250ms;
  cursor: pointer;
  position: relative;
  z-index: 6;
  &:hover,
  &:focus {
    opacity: 0.7;
    outline: none;
  }
  &.dark {
    color: ${(props) => props.theme['@white']};
    opacity: 0.75;
    &:hover {
      opacity: 1;
    }
  }
`

const NavbarItemEl = styled.li`
  position: relative;
`

const DropdownSlot = styled.div`
  position: absolute;
  left: 25%;
  margin-top: ${(props) => props.theme['@size-xs']};
  transform: translateX(-25%);
  perspective: 1500px;
  z-index: 4;
`

export interface NavbarItemProps {
  onMouseEnter: (index?: number) => void
  title: string
  index: number
  isDark?: boolean
  onClick?: () => void
}
export function NavbarItem({
  onMouseEnter,
  title,
  index,
  isDark,
  children,
  onClick
}: React.PropsWithChildren<NavbarItemProps>) {
  return (
    <NavbarItemEl onMouseEnter={() => onMouseEnter(index)} onFocus={() => onMouseEnter(index)}>
      <NavbarItemTitle className={isDark ? 'dark' : ''} onClick={onClick}>
        {title}
      </NavbarItemTitle>
      <DropdownSlot>{children}</DropdownSlot>
    </NavbarItemEl>
  )
}
