module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-remark-autolink-headers@2.3.15_gatsby@2.24.82/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"icon":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-remark-images@3.3.36_ggoanm7okovgvkohut5xe27d6u/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1140,"quality":90,"linkImagesToOriginal":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-canonical-urls@2.3.13_gatsby@2.24.82/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://prodigiesacademy.com"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-catch-links@2.3.15_gatsby@2.24.82/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-anchor-links@1.2.1/node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100,"duration":1000},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@2.4.35_gatsby@2.24.82/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Prodigies Academy - Standards-Based Music Curriculum for K-5 Education","short_name":"Prodigies Academy","start_url":"/","background_color":"#FFF","theme_color":"#009596","display":"standalone","icon":"src/images/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"3f0bb407cf2076af4e78e22cea6449d0"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-web-font-loader@1.0.4/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Nunito:400,600"]}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-intercom-spa@0.2.0/node_modules/gatsby-plugin-intercom-spa/gatsby-browser.js'),
      options: {"plugins":[],"app_id":"miij354u","include_in_development":true,"delay_timeout":5000},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-tagmanager@2.3.16_gatsby@2.24.82/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TC4M3F9","gtmAuth":"_xfN6qB13DWzaBxPys3K0Q","gtmPreview":"env-1"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-layout@1.10.0/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
