import React from 'react'
import styled from 'styled-components'
import { media, Capital } from '@thesisedu/web/dist'
import { UserOutlined, ClockCircleOutlined } from '@ant-design/icons'
import { Link } from 'gatsby'
import { Container, DropdownSection } from './Components'
import { PlatformItem } from './PlatformDropdown'

export function LearnDropdown() {
  return (
    <Container>
      <DropdownSection data-first-dropdown-section>
        <Column>
          <Capital color={'@text-color-secondary'}>Resources</Capital>
          <LinkList>
            <Link to={'/learn'}>Learn Home</Link>
            <Link to={'/learn/articles/'}>Knowledge Base</Link>
          </LinkList>
        </Column>
        <Column>
          <Capital color={'@text-color-secondary'}>&nbsp;</Capital>
          <LinkList>
            <Link to={'/learn/changelog/'}>Changelog</Link>
            <Link to={'/contact/'}>Help</Link>
          </LinkList>
        </Column>
      </DropdownSection>
      <DropdownSection>
        <LearnFooterItem
          title={'Schedule a Demo'}
          icon={<ClockCircleOutlined />}
          to={'mailto:sales@prodigiesacademy.com'}
        >
          Schedule a quick 20-minute overview of the platform.
        </LearnFooterItem>
        <LearnFooterItem
          title={'1-on-1 Training'}
          icon={<UserOutlined />}
          to={'mailto:support@prodigiesacademy.com'}
        >
          Schedule a 1-on-1 training session with our team.
        </LearnFooterItem>
      </DropdownSection>
    </Container>
  )
}

const LearnFooterItem = styled(PlatformItem)`
  width: 100%;
  padding: calc(${(props) => props.theme['@size-m']} / 2);
  ${media.md} {
    width: 50%;
  }
  ${media.lg} {
    padding: calc(${(props) => props.theme['@size-m']} / 2) ${(props) => props.theme['@size-m']};
  }
`
const LinkList = styled.div`
  margin-top: ${(props) => props.theme['@size-s']};
  > a {
    display: block;
    color: ${(props) => props.theme['@gray-5']};
    &:not(:last-child) {
      margin-bottom: ${(props) => props.theme['@size-xs']};
    }
    &:hover {
      color: ${(props) => props.theme['@gray-7']};
    }
  }
`
const Column = styled.div`
  width: 100%;
  padding: calc(${(props) => props.theme['@size-m']} / 2);
  ${media.md} {
    width: 50%;
  }
  ${media.lg} {
    padding: ${(props) => props.theme['@size-m']};
  }
`
