import React from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Link } from 'gatsby'
import { media, HSpaced, Space, FontWeight } from '@thesisedu/web/dist'
import { MenuOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import { Container } from './styled'
import lightLogo from '../images/academy-light-logo.svg'
import darkLogo from '../images/academy-dark-logo.svg'
import { BigBar } from './big-bar/BigBar'
import { MobileDropdown } from './big-bar/MobileDropdown'
import { FRONTEND } from '../constants'

export interface HeaderProps {
  isDark?: boolean
}
export function Header({ isDark }: HeaderProps) {
  const [visible, setVisible] = React.useState(false)
  const theme = React.useContext(ThemeContext)
  const itemProps = {
    className: isDark ? 'dark' : ''
  }
  const props = {
    ...itemProps,
    activeStyle: isDark ? { opacity: 1 } : { color: theme['@primary-color'] }
  }
  return (
    <ResponsiveContainer>
      <HSpaced space={'@size-l'} className={'large-content'}>
        <Link to={'/'}>
          <img
            style={{ height: isDark ? '50px' : '40px' }}
            src={isDark ? darkLogo : lightLogo}
            alt={'Prodigies Academy'}
          />
        </Link>
        <BigBar isDark={isDark} />
        <ContactNavigationItem as={Link} {...props} to={'/contact'}>
          Contact Us
        </ContactNavigationItem>
        <Space />
        <OutboundLink href={`${FRONTEND}/auth/login`}>
          <NavigationItem {...itemProps}>Login</NavigationItem>
        </OutboundLink>
      </HSpaced>
      <HSpaced space={'@size-s'} className={'small-content'}>
        <MobileDropdown onVisibleChange={setVisible} visible={visible} />
        <Link to={'/'}>
          <img
            style={{ height: '30px' }}
            src={isDark ? darkLogo : lightLogo}
            alt={'Prodigies Academy'}
          />
        </Link>
        <Space />
        <Button
          icon={<MenuOutlined />}
          className={'menu-button'}
          onClick={() => setVisible((v) => !v)}
        />
      </HSpaced>
    </ResponsiveContainer>
  )
}

const ResponsiveContainer = styled(Container)`
  margin-top: ${(props) => props.theme['@size-xl']};
  position: relative;
  > div {
    transition: opacity 0.25s linear;
    &.small-content {
      width: 100%;
      .menu-button {
        margin-right: 0 !important;
      }
      > .trial-button {
        display: none;
        margin-left: ${(props) => props.theme['@size-s']};
      }
    }
    &.large-content {
      position: absolute;
      top: 0;
      left: ${(props) => props.theme['@size-l']};
      right: ${(props) => props.theme['@size-l']};
      pointer-events: none;
      opacity: 0;
    }
  }
  ${media.lg} {
    > div.large-content {
      pointer-events: all;
      opacity: 1;
    }
    > div.small-content {
      pointer-events: none;
      opacity: 0;
    }
  }
  ${media.sm} {
    > div.small-content {
      .trial-button {
        display: block;
      }
    }
  }
`
const NavigationItem = styled.span`
  transition: color 0.1s linear, opacity 0.1s linear;
  font-size: ${(props) => props.theme['@font-size-lg']};
  color: ${(props) => props.theme['@gray-5']};
  font-weight: ${() => FontWeight.SemiBold};
  &:hover:not(.dark) {
    color: ${(props) => props.theme['@gray-7']};
  }
  &.dark {
    color: ${(props) => props.theme['@white']};
    opacity: 0.75;
    &:hover {
      opacity: 1;
    }
  }
`
const ContactNavigationItem = styled(NavigationItem)`
  margin-left: calc(-${(props) => props.theme['@size-l']} / 2);
`
