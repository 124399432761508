import React from 'react'
import { Flipper } from 'react-flip-toolkit'
import { navigate } from 'gatsby'
import { LessonPiecesDropdown } from './DropdownContents/LessonPiecesDropdown'
import { PlatformDropdown } from './DropdownContents/PlatformDropdown'
import { LearnDropdown } from './DropdownContents/LearnDropdown'
import { Navbar } from './Navbar'
import { NavbarItem } from './Navbar/NavbarItem'
import { DropdownContainer } from './DropdownContainer'

const navbarConfig = [
  { title: 'Lessons', dropdown: LessonPiecesDropdown, path: '/#lessons' },
  { title: 'Platform', dropdown: PlatformDropdown, path: '/#platform' },
  { title: 'Learn', dropdown: LearnDropdown, path: '/learn' }
]

const DURATION = 400
export interface BigBarProps {
  isDark?: boolean
}
export function BigBar({ isDark }: BigBarProps) {
  const [activeIndices, setActiveIndices] = React.useState<number[]>([])
  const [animatingOut, setAnimatingOut] = React.useState(false)
  const animatingOutTimeout = React.useRef<any>()

  let CurrentDropdown: React.FC<any> | undefined
  let PrevDropdown: React.FC<any> | undefined
  let direction: 'right' | 'left' | undefined

  const currentIndex = activeIndices[activeIndices.length - 1]
  const prevIndex = activeIndices.length > 1 && activeIndices[activeIndices.length - 2]

  if (typeof currentIndex === 'number') CurrentDropdown = navbarConfig[currentIndex].dropdown
  if (typeof prevIndex === 'number') {
    PrevDropdown = navbarConfig[prevIndex].dropdown
    direction = currentIndex > prevIndex ? 'right' : 'left'
  }

  const resetDropdownState = React.useCallback((i?: number) => {
    setActiveIndices(typeof i === 'number' ? [i] : [])
    setAnimatingOut(false)
    animatingOutTimeout.current = undefined
  }, [])
  const onMouseEnter = React.useCallback((i?: number) => {
    if (animatingOutTimeout.current) {
      clearTimeout(animatingOutTimeout.current)
      resetDropdownState(i)
      return
    }
    if (activeIndices[activeIndices.length - 1] === i) return
    setActiveIndices((prev) => prev.concat(i!))
    setAnimatingOut(false)
  }, [])

  return (
    <Flipper
      flipKey={currentIndex}
      // @ts-ignore
      spring={'noWobble'}
    >
      <Navbar
        onMouseLeave={() => {
          setAnimatingOut(true)
          animatingOutTimeout.current = setTimeout(resetDropdownState, DURATION)
        }}
      >
        {navbarConfig.map((n, index) => (
          <NavbarItem
            key={n.title}
            title={n.title}
            index={index}
            onMouseEnter={onMouseEnter}
            isDark={isDark}
            onClick={
              n.path
                ? () => {
                    navigate(n.path)
                    document.querySelector(n.path.replace('/', ''))?.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center'
                    })
                  }
                : undefined
            }
          >
            {currentIndex === index && CurrentDropdown ? (
              <DropdownContainer
                direction={direction}
                animatingOut={animatingOut}
                duration={DURATION}
              >
                <CurrentDropdown />
                {PrevDropdown ? <PrevDropdown /> : null}
              </DropdownContainer>
            ) : null}
          </NavbarItem>
        ))}
      </Navbar>
    </Flipper>
  )
}
