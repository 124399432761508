import React, { useState } from 'react'
import { Block, Body, H3Alternate, Modal } from '@thesisedu/web/dist'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import { Divider } from 'antd'
import { FRONTEND } from '../constants'

export function StudentLoginLink() {
  const [visible, setVisible] = useState(false)
  return (
    <>
      <Modal
        footer={null}
        title={'How do you login?'}
        visible={visible}
        onCancel={() => setVisible(false)}
        width={500}
      >
        <OutboundLink href={`${FRONTEND}/auth/login`}>
          <Block marginTop={'@size-l'}>
            <H3Alternate color={'@primary-color'}>I use an Email Address &rarr;</H3Alternate>
            <Body color={'@text-color-secondary'}>
              You will be asked to enter your email address and password.
            </Body>
          </Block>
        </OutboundLink>
        <Divider>or</Divider>
        <OutboundLink href={`${FRONTEND}/auth/login`}>
          <Block>
            <H3Alternate color={'@primary-color'}>I use a Google Account &rarr;</H3Alternate>
            <Body color={'@text-color-secondary'}>
              You will be asked to login with your district Google Account.
            </Body>
          </Block>
        </OutboundLink>
        <Divider>or</Divider>
        <OutboundLink href={`${FRONTEND}/auth/student`}>
          <Block>
            <H3Alternate color={'@primary-color'}>
              I use a Student ID &amp; Teacher ID &rarr;
            </H3Alternate>
            <Body color={'@text-color-secondary'}>
              You will be asked to enter both your Teacher's ID and your unique Student ID.
            </Body>
          </Block>
        </OutboundLink>
      </Modal>
      <a
        href={`${FRONTEND}/auth/login`}
        onClick={(e) => {
          e.preventDefault()
          setVisible(true)
        }}
      >
        Student Login
      </a>
    </>
  )
}
