import React from 'react'
import styled from 'styled-components'
import { CloseOutlined } from '@ant-design/icons'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import { BrochureCardSmall } from '../styled'
import { LessonPiecesDropdown } from './DropdownContents/LessonPiecesDropdown'
import { PlatformDropdown } from './DropdownContents/PlatformDropdown'
import { LearnDropdown } from './DropdownContents/LearnDropdown'
import { useHashEffect } from '../useHashEffect'
import { FRONTEND } from '../../constants'

export interface MobileDropdownProps {
  visible?: boolean
  onVisibleChange: (visible: boolean) => void
}
export function MobileDropdown({ visible, onVisibleChange }: MobileDropdownProps) {
  useHashEffect(() => {
    onVisibleChange(false)
  })

  // Automatically listen for click events on valid links, and hide the overlay
  // once something is clicked. Otherwise, since we're using the old layout
  // functionality, the page will change but the menu will not go away.
  const containerRef = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    const clickHandler = () => {
      onVisibleChange(false)
    }
    containerRef.current?.querySelectorAll<HTMLAnchorElement>('a').forEach((element) => {
      element.addEventListener('click', clickHandler)
    })
    return () => {
      containerRef.current?.querySelectorAll<HTMLAnchorElement>('a').forEach((element) => {
        element.removeEventListener('click', clickHandler)
      })
    }
  }, [])
  return (
    <DropdownContainer className={visible ? 'visible' : undefined} ref={containerRef}>
      <CloseContainer onClick={() => onVisibleChange(false)}>
        <CloseOutlined />
      </CloseContainer>
      <MobileDropdownHeader>
        <OutboundLink href={`${FRONTEND}/auth/login`}>Login</OutboundLink>
      </MobileDropdownHeader>
      <LessonPiecesDropdown />
      <PlatformDropdown />
      <LearnDropdown />
    </DropdownContainer>
  )
}

const MobileDropdownHeader = styled.div`
  padding: ${(props) => props.theme['@size-xxl']} ${(props) => props.theme['@size-l']}
    ${(props) => props.theme['@size-l']} ${(props) => props.theme['@size-l']};
  background: ${(props) => props.theme['@background-color-base']};
  border-radius: ${(props) => props.theme['@border-radius-base']};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 ${(props) => props.theme['@size-m']} 0;
  > * {
    display: block;
    margin: 0 ${(props) => props.theme['@size-s']};
    &:last-child {
      margin-left: auto;
    }
  }
`
const CloseContainer = styled.span`
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  color: ${(props) => props.theme['@gray-5']};
  font-size: ${(props) => props.theme['@size-s']};
  padding: ${(props) => props.theme['@size-l']};
  cursor: pointer;
  transition: color 0.25s linear;
  z-index: 20;
  &:hover {
    color: ${(props) => props.theme['@gray-7']};
  }
`
const DropdownContainer = styled(BrochureCardSmall)`
  z-index: 10;
  position: fixed;
  top: ${(props) => props.theme['@size-m']};
  left: ${(props) => props.theme['@size-m']};
  right: ${(props) => props.theme['@size-m']};
  bottom: ${(props) => props.theme['@size-m']};
  width: calc(100% - ${(props) => props.theme['@size-m']} * 2);
  overflow-y: auto;
  max-height: calc(100vh - ${(props) => props.theme['@size-l']} * 2);
  pointer-events: none;
  padding: 0 0 ${(props) => props.theme['@size-l']} 0;
  transform-origin: 50% 0;
  transition: transform 0.5s cubic-bezier(0.7, 0, 0.84, 0),
    opacity 0.5s cubic-bezier(0.7, 0, 0.84, 0);
  transform: scale(0.95) translateY(-${(props) => props.theme['@size-m']});
  opacity: 0;
  > div:not(${MobileDropdownHeader}) {
    width: 100% !important;
    padding: 0 ${(props) => props.theme['@size-m']};
  }
  &.visible {
    transition: transform 0.5s cubic-bezier(0.16, 1, 0.3, 1),
      opacity 0.5s cubic-bezier(0.16, 1, 0.3, 1);
    transform: scale(1) translateY(0);
    opacity: 1;
    pointer-events: all;
  }
`
