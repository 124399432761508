import React from 'react'
import styled from 'styled-components'
import {
  ArrowRightOutlined,
  UnorderedListOutlined,
  TeamOutlined,
  PlusOutlined,
  LineChartOutlined,
  AppstoreAddOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
  RocketOutlined
} from '@ant-design/icons'
import { media, Body, BodySmall, FontWeight } from '@thesisedu/web/dist'
import { Container, DropdownSection, RightArrowContainer } from './Components'

export function PlatformDropdown() {
  return (
    <Container style={{ width: 900 }}>
      <DropdownSection data-first-dropdown-section>
        <PlatformItem title={'Outline'} icon={<UnorderedListOutlined />} hash={'outline'}>
          Manage your class &amp; content from one view.
        </PlatformItem>
        <PlatformItem title={'Create'} icon={<PlusOutlined />} hash={'create'}>
          Add &amp; place your own content in any class.
        </PlatformItem>
        <PlatformItem title={'Reports'} icon={<LineChartOutlined />} hash={'reports'}>
          Get actionable insights on student activity &amp; performance.
        </PlatformItem>
        <PlatformItem title={'Explore'} icon={<SearchOutlined />} hash={'explore'}>
          Quickly search our content library and use pieces &amp; parts in any class.
        </PlatformItem>
        <PlatformItem title={'Integrate'} icon={<AppstoreAddOutlined />} hash={'integrate'}>
          Sync with your LMS for single sign-on, grade sync, &amp; rostering.
        </PlatformItem>
        <PlatformItem title={'More...'} icon={<RocketOutlined />} hash={'outline'}>
          PA is packed with teacher-inspired features. Learn more about all it can do.
        </PlatformItem>
      </DropdownSection>
    </Container>
  )
}

export interface PlatformItemProps {
  title: string
  hash?: string
  to?: string
  icon: React.ReactElement
  className?: string
  onClick?: () => void
}
export function PlatformItem({
  children,
  title,
  hash,
  to,
  icon,
  className,
  onClick
}: React.PropsWithChildren<PlatformItemProps>) {
  return (
    // @ts-ignore weirdness with the as and props.
    <PlatformItemContainer
      className={className}
      onClick={
        onClick
          ? (e) => {
              e.preventDefault()
              onClick()
            }
          : undefined
      }
      href={to || `/#${hash}`}
    >
      <div>{icon}</div>
      <div>
        <Body weight={FontWeight.SemiBold}>
          {title}
          <RightArrowContainer>
            <ArrowRightOutlined />
          </RightArrowContainer>
        </Body>
        <BodySmall>{children}</BodySmall>
      </div>
    </PlatformItemContainer>
  )
}

const PlatformItemContainer = styled.a`
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: calc(${(props) => props.theme['@size-m']} / 2);
  cursor: pointer;
  > div:first-child {
    margin-right: 12px;
    font-size: ${(props) => props.theme['@size-s']};
  }
  * {
    transition: color 0.25s linear;
    color: ${(props) => props.theme['@gray-5']};
  }
  &:hover {
    * {
      color: ${(props) => props.theme['@gray-7']};
    }
    ${RightArrowContainer} > * {
      left: 0;
      opacity: 100;
    }
  }
  ${media.md} {
    width: 33%;
  }
  ${media.lg} {
    width: 25%;
  }
`
