import React from 'react'
import styled from 'styled-components'
import { media } from '@thesisedu/web/dist'
import { Container } from './styled'
import { Header } from './Header'

const SCROLL_THRESHOLD = 200
export function PinHeader() {
  const [isPinned, setIsPinned] = React.useState(false)
  React.useEffect(() => {
    const handler = () => {
      if (window.scrollY > SCROLL_THRESHOLD) {
        setIsPinned(true)
      } else {
        setIsPinned(false)
      }
    }
    window.addEventListener('scroll', handler)
    return () => window.removeEventListener('scroll', handler)
  }, [])

  return (
    <Outer className={isPinned ? 'pinned' : ''}>
      <Container>
        <Header />
      </Container>
    </Outer>
  )
}

const Outer = styled.div`
  position: fixed;
  transition: top 0.25s cubic-bezier(0.7, 0, 0.84, 0);
  top: -100px;
  width: 100%;
  z-index: 20;
  > div {
    background: ${(props) => props.theme['@gray-0']};
    border-radius: ${(props) => props.theme['@border-radius-base']};
    box-shadow: ${(props) => props.theme['@shadow-large']};
    padding-top: ${(props) => props.theme['@size-xm']};
    padding-bottom: ${(props) => props.theme['@size-xm']};
    > div {
      margin: 0 -${(props) => props.theme['@size-l']};
    }
  }
  &.pinned {
    top: 0;
    transition: top 0.5s cubic-bezier(0.16, 1, 0.3, 1);
    ${media.lg} {
      top: ${(props) => props.theme['@size-l']};
    }
  }
`
