import React from 'react'

export function useHashEffect(callback: () => void) {
  const callbackRef = React.useRef<() => void>(callback)
  React.useEffect(() => {
    callbackRef.current = callback
  }, [callback])
  React.useEffect(() => {
    const handler = () => {
      callbackRef.current()
    }
    window.addEventListener('hashchange', handler, false)
    handler()
    return () => {
      window.removeEventListener('hashchange', handler, false)
    }
  }, [])
}
