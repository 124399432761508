import * as React from 'react'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { ResponsiveContextProvider } from '@thesisedu/web/dist'
import { IndexLayoutQueryQuery } from '../graphqlTypes'

import 'modern-normalize'
import './layout.less'
import { Footer } from '../components/Footer'
import { BlobHeaderBackground } from '../components/BlobHeaderBackground'
import { Header } from '../components/Header'
import { ResponsiveThemeProvider } from './theme'
import { PinHeader } from '../components/PinHeader'

const IntenseLayout: React.FC = ({ children }) => (
  <StaticQuery
    query={graphql`
      query IntenseLayoutQuery {
        site {
          siteMetadata {
            title
            description
            keywords
          }
        }
      }
    `}
    render={(data: IndexLayoutQueryQuery) => (
      <ResponsiveContextProvider>
        <ResponsiveThemeProvider>
          <div>
            <Helmet
              title={data.site.siteMetadata.title}
              meta={[
                { name: 'description', content: data.site.siteMetadata.description },
                { name: 'keywords', content: data.site.siteMetadata.keywords }
              ]}
            />
            <BlobHeaderBackground />
            <PinHeader />
            <Header isDark />
            {children}
            <Footer />
          </div>
        </ResponsiveThemeProvider>
      </ResponsiveContextProvider>
    )}
  />
)

export default IntenseLayout
