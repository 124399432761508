import React from 'react'
import { Flipped } from 'react-flip-toolkit'
import { DropdownRoot, Caret, DropdownBackground, AltBackground, InvertedDiv } from './Components'
import { FadeContents } from './FadeContents'

const getFirstDropdownSectionHeight = (el?: HTMLElement) => {
  if (!el || !el.querySelector || !el.querySelector('*[data-first-dropdown-section]')) return 0
  return el.querySelector<HTMLDivElement>('*[data-first-dropdown-section]')!.offsetHeight
}

interface UpdateAltBackgroundOpts {
  altBackground: HTMLDivElement
  prevDropdown?: HTMLElement
  currentDropdown?: HTMLElement
}
const updateAltBackground = ({
  altBackground,
  prevDropdown,
  currentDropdown
}: UpdateAltBackgroundOpts) => {
  const prevHeight = getFirstDropdownSectionHeight(prevDropdown)
  const currentHeight = getFirstDropdownSectionHeight(currentDropdown)

  const immediateSetTranslateY = (el: HTMLDivElement, translateY: number) => {
    el.style.transform = `translateY(${translateY}px)`
    el.style.transition = 'transform 0s'
    requestAnimationFrame(() => {
      el.style.transitionDuration = ''
    })
  }

  if (prevHeight) {
    immediateSetTranslateY(altBackground, prevHeight)
    requestAnimationFrame(() => {
      altBackground.style.transform = `translateY(${currentHeight}px)`
    })
  } else {
    immediateSetTranslateY(altBackground, currentHeight)
  }
}

export interface DropdownContainerProps {
  animatingOut?: boolean
  direction: 'left' | 'right' | undefined
  duration: number
}
export function DropdownContainer({
  animatingOut,
  direction,
  duration,
  children
}: React.PropsWithChildren<DropdownContainerProps>) {
  const altBackgroundEl = React.useRef<HTMLDivElement>()
  const prevDropdownEl = React.useRef<HTMLDivElement>()
  const currentDropdownEl = React.useRef<HTMLDivElement>()
  React.useEffect(() => {
    updateAltBackground({
      altBackground: altBackgroundEl.current!,
      prevDropdown: prevDropdownEl.current,
      currentDropdown: currentDropdownEl.current
    })
  }, [])

  const [currentDropdown, prevDropdown] = React.Children.toArray(children)
  return (
    <DropdownRoot direction={direction} animatingOut={animatingOut} duration={duration}>
      <Flipped flipId={'dropdown-caret'}>
        <Caret />
      </Flipped>
      <Flipped flipId={'dropdown'}>
        <DropdownBackground>
          <Flipped inverseFlipId={'dropdown'}>
            <InvertedDiv>
              <AltBackground ref={altBackgroundEl} duration={duration} />
              <FadeContents direction={direction} duration={duration} ref={currentDropdownEl}>
                {currentDropdown}
              </FadeContents>
            </InvertedDiv>
          </Flipped>
          <Flipped inverseFlipId={'dropdown'} scale>
            <InvertedDiv absolute>
              {prevDropdown ? (
                <FadeContents
                  animatingOut
                  direction={direction}
                  duration={duration}
                  ref={prevDropdownEl}
                >
                  {prevDropdown}
                </FadeContents>
              ) : null}
            </InvertedDiv>
          </Flipped>
        </DropdownBackground>
      </Flipped>
    </DropdownRoot>
  )
}
