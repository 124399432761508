import styled from 'styled-components'

export const DropdownSection = styled.div`
  padding: calc(${(props) => props.theme['@size-m']} / 2);
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
export const Container = styled.div`
  width: 600px;
`
export const RightArrowContainer = styled.span`
  overflow: hidden;
  position: relative;
  font-size: 12px;
  margin-left: 6px;
  display: inline-block;
  vertical-align: middle;
  > * {
    position: relative;
    left: -${(props) => props.theme['@size-s']};
    opacity: 0;
    transition: left 0.25s ease-in-out, opacity 0.25s linear !important;
  }
`
