import React from 'react'
import { useResponsive, ResponsiveType } from '@thesisedu/web/dist'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import * as theme from '../../theme'

export const ResponsiveThemeProvider: React.FC = ({ children }) => {
  const isLarge = !useResponsive(ResponsiveType.LessThan, 'lg')
  return (
    <ThemeProvider theme={isLarge ? theme.theme : theme.smallTheme}>
      {children}
      <GlobalStyles />
    </ThemeProvider>
  )
}

const GlobalStyles = createGlobalStyle`
  h1 {
    font-weight: 800;
    font-size: ${(props) => props.theme['@size-xl']} !important;
    line-height: 1.2;
    letter-spacing: -1px;
  }
  h2 {
    font-weight: 800;
    font-size: ${(props) => props.theme['@size-l']} !important;
    letter-spacing: -0.75px;
    &:not(:first-child) {
      margin-top: ${(props) => props.theme['@size-l']};
    }
  }
`
