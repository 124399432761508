const resolve = require('object-reference')
const { transparentize, lighten } = require('polished')

const colors = {
  '@blue': '#009596',
  '@blue-light': '#EBFFFF',
  '@red': '#EB2627',
  '@red-light': '#FFEBEB',
  '@green': '#6BBE46',
  '@green-light': '#F1FFEB',
  '@orange': '#F89721',
  '@orange-light': '#FFF6EB',
  '@white': '#FFFFFF',
  '@pink': '#EB6BA2',
  '@purple': '#634683',
  '@yellow': '#F5EB0B',

  '@gray-0': '#FFF',
  '@gray-1': '#F2F4F7',
  '@gray-2': '#E6EBF5',
  '@gray-3': '#D8DFF0',
  '@gray-4': '#8D98B3',
  '@gray-5': '#626F8C',
  '@gray-6': '#2A3859',
  '@gray-7': '#19223F',
}

const sizes = {
  '@size-xxs': '4px',
  '@size-xs': '8px',
  '@size-s': '16px',
  '@size-xm': '18px',
  '@size-m': '22px',
  '@size-l': '32px',
  '@size-xl': '48px',
  '@size-xxl': '64px',
  '@size-section-sm': '80px',
  '@size-section-md': '120px'
}

const themeBase = () => ({
  '@font-family': "'Nunito', sans-serif;",
  '@code-family':
    "'Hasklig', 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace",

  '@shadow-color': transparentize(0.85, colors['@gray-4']),
  '@shadow-small': `0.5px 1px 2px ${transparentize(0.45, colors['@gray-4'])}`,
  '@shadow-medium': `2px 2px ${sizes['@size-xs']} ${transparentize(0.65, colors['@gray-4'])}`,
  '@shadow-large': `2px ${sizes['@size-xs']} ${sizes['@size-s']} ${transparentize(
    0.65,
    colors['@gray-4']
  )}`,

  '@component-background': '$.@gray-0',
  '@background-color-light': transparentize(0.5, colors['@gray-1']),
  '@background-color-base': '$.@gray-1',
  '@background-color-dark': '$.@gray-6',
  '@background-color-black': '$.@gray-7',
  '@text-color-dark': '$.@gray-1',
  '@text-color-secondary-dark': '$.@gray-5',
  '@input-bg': '$.@gray-1',
  '@picker-bg': '$.@gray-1',
  '@btn-default-bg': '$.@gray-1',
  '@select-background': '$.@gray-1',
  '@item-hover-bg': '$.@gray-1',
  '@primary-color': '$.@blue',
  '@primary-color-light': lighten(0.35, colors['@blue']),
  '@primary-color-extra-light': '$.@blue-light',
  '@text-color': '$.@gray-6',
  '@text-color-secondary': '$.@gray-5',
  '@heading-color': '$.@gray-7',
  '@border-color-base': '$.@gray-2',
  '@border-color-split': transparentize(0.25, colors['@gray-2']),
  '@border-radius-base': '$.@size-xxs',
  '@border-radius-large': '$.@size-s',
  '@border-width-base': '0px',
  '@border-color-dark': '$.@gray-3',

  '@layout-header-background': 'transparent',
  '@layout-body-background': 'white',

  '@menu-bg': 'transparent',
  '@menu-inline-toplevel-item-height': '$.@size-xl',
  '@dropdown-line-height': '$.@size-l',

  '@tabs-horizontal-margin': `0 ${sizes['@size-m']} 0 0`,
  '@tabs-horizontal-padding': '@padding-sm 0',
  '@tabs-horizontal-padding-lg': '@padding-md 0',
  '@tabs-horizontal-padding-sm': '@padding-xs 0',
  '@tabs-ink-bar-color': 'transparent',

  '@modal-mask-bg': transparentize(0.15, colors['@gray-3']),

  '@checkbox-size': '$.@size-m',
  '@checkbox-check-bg': '$.@gray-1',

  '@page-header-padding': '0',
  '@page-header-padding-vertical': '0',
  '@page-header-back-color': '@text-color-secondary',

  '@collapse-header-padding': `${sizes['@size-xs']} 0`,
  '@collapse-header-bg': '@component-background',
  '@collapse-content-padding': `${sizes['@size-xxs']} ${sizes['@size-s']} ${sizes['@size-m']} ${sizes['@size-s']}`,

  '@avatar-bg': '$.@gray-5',

  '@modal-footer-padding-vertical': '$.@size-s',
  '@modal-body-padding': '$.@size-m',
  '@modal-footer-padding-horizontal': '$.@size-m',

  '@heading-1-size': '$.@size-xl',
  '@heading-2-size': '$.@size-l',
  '@heading-3-size': '$.@size-s',

  '@font-size-base': '$.@size-s',
  '@font-size-lg': '$.@size-xm',
  '@font-size-sm': '14px',
  '@font-size-xs': '10px',

  '@badge-font-size': '12px',

  '@padding-lg': '$.@size-m',
  '@padding-md': '$.@size-xm',
  '@padding-sm': '$.@size-s',
  '@padding-xs': '$.@size-xs',

  '@form-item-margin-bottom': '$.@size-l',

  '@input-height-base': '@size-xl - @size-xs',
  '@input-height-lg': '@input-height-base + @size-xs',
  '@input-height-sm': '@input-height-base - @size-xs',
  '@btn-height-base': '@input-height-base',
  '@btn-height-lg': '@input-height-lg',
  '@btn-height-sm': '@input-height-sm',

  '@switch-height': '26px',
  '@switch-sm-height': '20px',
  '@switch-min-width': '50px',
  '@switch-sm-min-width': '38px',

  '@line-height-base': '1.5',

  // Original custom BusinessU colors:
  '@light-text-color': '$.@gray-1',

  '@PageHeader-topMargin': '$.@size-xxs'
})

module.exports.theme = resolve({
  ...colors,
  ...sizes,
  ...themeBase()
})

const smallSizes = {
  ...sizes,
  '@size-xxs': '2px',
  '@size-xs': '6px',
  '@size-s': '12px',
  '@size-xm': '16px',
  '@size-m': '20px',
  '@size-l': '24px',
  '@size-xl': '36px',
  '@size-xxl': '60px',
  '@size-section-sm': '75px',
  '@size-section-md': '100px'
}
module.exports.smallTheme = resolve({
  ...colors,
  ...smallSizes,
  ...themeBase(),
  '@font-size-base': sizes['@size-s'],
  // '@font-size-lg': sizes['@size-xm'],
  '@font-size-sm': '12px',
  '@font-size-xs': '10px'
})

const originalColors = { ...colors }
colors['@gray-0'] = originalColors['@gray-7']
colors['@gray-1'] = originalColors['@gray-6']
colors['@gray-2'] = originalColors['@gray-5']
colors['@gray-3'] = originalColors['@gray-4']
colors['@gray-4'] = originalColors['@gray-3']
colors['@gray-5'] = originalColors['@gray-2']
colors['@gray-6'] = originalColors['@gray-1']
colors['@gray-7'] = originalColors['@gray-0']

module.exports.darkTheme = resolve({
  ...colors,
  ...sizes,
  ...themeBase()
})
