import styled, { keyframes } from 'styled-components'
import { promoteLayer } from './utils'

type Props = any
const getDropdownRootKeyFrame = ({ animatingOut, direction }: Props) => {
  if (!animatingOut && direction) return null
  return keyframes`
  from {
    transform: ${animatingOut ? 'rotateX(0)' : 'rotateX(-15deg)'};
    opacity: ${animatingOut ? 1 : 0};
  }
  to {
    transform: ${animatingOut ? 'rotateX(-15deg)' : 'rotateX(0)'};
    opacity: ${animatingOut ? 0 : 1};
  }
`
}

export const DropdownRoot = styled.div<{ duration: number }>`
  transform-origin: 0 0;
  ${promoteLayer}
  animation-name: ${getDropdownRootKeyFrame};
  animation-duration: ${(props) => props.duration}ms;
  /* use 'forwards' to prevent flicker on leave animation */
  animation-fill-mode: forwards;
  /* flex styles will center the caret child component */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  top: -20px;
  z-index: 6;
`

export const Caret = styled.div`
  width: 0;
  height: 0;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent ${(props) => props.theme['@gray-0']};
  /* make sure it's above the main dropdown container so now box-shadow bleeds over it */
  z-index: 1;
  position: relative;
  /* prevent any gap in between caret and main dropdown */
  top: 1px;
  left: calc(25% + ${(props) => props.theme['@size-s']});
`

export const DropdownBackground = styled.div`
  transform-origin: 0 0;
  background-color: ${(props) => props.theme['@gray-0']};
  border-radius: ${(props) => props.theme['@border-radius-base']};
  overflow: hidden;
  position: relative;
  box-shadow: 0 4px 15px 0 rgba(55, 62, 74, 0.14);
  ${promoteLayer}
`

export const AltBackground = styled.div<{ duration: number }>`
  background-color: ${(props) => props.theme['@background-color-base']};
  width: 300%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -100%;
  transform-origin: 0 0;
  z-index: 0;
  transition: transform ${(props) => props.duration}ms;
`

export const InvertedDiv = styled.div<{ absolute?: boolean }>`
  ${promoteLayer}
  position: ${(props) => (props.absolute ? 'absolute' : 'relative')};
  top: 0;
  left: 0;
  &:first-of-type {
    z-index: 1;
  }
  &:not(:first-of-type) {
    z-index: -1;
  }
`
