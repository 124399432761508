import React from 'react'
import styled from 'styled-components'

const NavbarEl = styled.nav`
  margin: auto;
`

const NavbarList = styled.ul`
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
`

export interface NavbarProps {
  onMouseLeave?: () => void
}
export function Navbar({ children, onMouseLeave }: React.PropsWithChildren<NavbarProps>) {
  return (
    <NavbarEl onMouseLeave={onMouseLeave}>
      <NavbarList>{children}</NavbarList>
    </NavbarEl>
  )
}
