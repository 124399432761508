import React from 'react'
import { graphql } from 'gatsby'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Block, media, Body, HideOn, ShowOn } from '@thesisedu/web/dist'
import { Button } from 'antd'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { LessonPieceFragment } from '../../graphqlTypes'
import { BrochureCardSmall } from '../styled'
import { FRONTEND } from '../../constants'

export interface LessonPieceProps {
  piece: LessonPieceFragment
  onBack: () => void
}
export function LessonPiece({ piece, onBack }: LessonPieceProps) {
  const { frontmatter } = piece.childMarkdownRemark
  const back = (
    <HideOn size={'lg'}>
      <Block marginBottom={'@size-s'}>
        <Button type={'link'} icon={<ArrowLeftOutlined />} onClick={onBack}>
          Back to List
        </Button>
      </Block>
    </HideOn>
  )
  return (
    <Container color={frontmatter.color}>
      <div>
        {back}
        <Block marginBottom={'@size-l'}>
          <BrochureCardSmall style={{ width: '100%' }}>
            {frontmatter.image.childImageSharp ? (
              <Img
                fluid={frontmatter.image.childImageSharp.fluid}
                alt={`Image for ${frontmatter.title}`}
              />
            ) : (
              <img
                src={frontmatter.image.publicURL}
                alt={frontmatter.title}
                style={{ width: '100%' }}
              />
            )}
          </BrochureCardSmall>
        </Block>
        <Block marginTop={'@size-m'} marginBottom={'@size-s'}>
          <Body useDiv dangerouslySetInnerHTML={{ __html: piece.childMarkdownRemark.html }} />
        </Block>
      </div>
    </Container>
  )
}

const Container = styled.div<{ color: string }>`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;
  a {
    color: ${(props) => props.theme[props.color !== '@gray-6' ? props.color : '@primary-color']};
  }
  > div:first-child {
    width: 100%;
  }
  ${media.lg} {
    margin-top: ${(props) => props.theme['@size-xl']};
    > :last-child {
      margin-left: ${(props) => props.theme['@size-xl']};
    }
  }
`

export const fragment = graphql`
  fragment LessonPiece on File {
    id
    childMarkdownRemark {
      html
      frontmatter {
        title
        subtitle
        icon {
          publicURL
        }
        image {
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
          publicURL
        }
        menu {
          title
          description
        }
        color
      }
    }
  }
`
