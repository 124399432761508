import styled from 'styled-components'
import { media, H2, H1, FontWeight, BodyLarge } from '@thesisedu/web/dist'
import { Input, Button } from 'antd'
import { transparentize } from 'polished'

export const Container = styled.div`
  max-width: 1184px;
  margin: 0 auto;
  padding: 0 ${(props) => props.theme['@size-l']};
`

export const LargeH1 = styled(H1)`
  &.ant-typography {
    font-weight: 600 !important;
    font-size: 40px;
    line-height: 44px;
    margin-bottom: ${(props) => props.theme['@size-xs']};
    ${media.lg} {
      font-size: 53px !important;
      line-height: 55px;
      letter-spacing: -1px;
      margin-bottom: ${(props) => props.theme['@size-s']};
    }
  }
`
export const Subtitle = styled.div`
  font-size: 18px;
  line-height: 26px;
  color: ${(props) => props.theme['@text-color-secondary']};
  ${media.lg} {
    font-size: 22px;
    line-height: 30px;
  }
  a {
    text-decoration: underline;
    color: inherit;
    &:hover {
      color: ${(props) => props.theme['@gray-7']};
    }
  }
`

export const BrochureCard = styled.div`
  border-radius: ${(props) => props.theme['@border-radius-large']};
  background: ${(props) => props.theme['@gray-0']};
  box-shadow: 0 5px 50px 0 ${(props) => transparentize(0.75, props.theme['@gray-4'])};
`

export const BrochureCardSmall = styled.div`
  border-radius: ${(props) => props.theme['@border-radius-large']};
  background: ${(props) => props.theme['@gray-0']};
  box-shadow: 0 4px 15px 0 ${(props) => transparentize(0.86, props.theme['@gray-4'])};
  position: relative;
  overflow: hidden;
`

export const HoverBrochureCardSmall = styled(BrochureCardSmall)`
  transition: background 0.5s linear, box-shadow 0.5s cubic-bezier(0.16, 1, 0.3, 1),
    transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  transform: translateY(0);
  cursor: pointer;
  &:hover,
  &:focus {
    transform: translateY(-6px);
    box-shadow: 0 12px 22px 0 ${(props) => transparentize(0.86, props.theme['@gray-6'])};
  }
  &:active {
    transform: translateY(1px);
    box-shadow: 0 3px 12px 0 ${(props) => transparentize(0.86, props.theme['@gray-6'])};
  }
`

// From http://heropatterns.com/
export const PatternBox = styled.div`
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23f7f7f9' fill-opacity='1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
`

export const GlowButton = styled(Button)`
  transition: background 0.5s linear, box-shadow 0.5s cubic-bezier(0.16, 1, 0.3, 1),
    transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  box-shadow: 0 2px 10px 0 ${(props) => transparentize(0.75, props.theme['@primary-color'])};
  transform: translateY(0);
  &:hover,
  &:focus {
    transform: translateY(-3px);
    box-shadow: 0 5px 10px 0 ${(props) => transparentize(0.3, props.theme['@primary-color'])};
    background: ${(props) => props.theme['@primary-color']};
  }
  &:active {
    transform: translateY(1px);
    box-shadow: 0 1px 10px 0 ${(props) => props.theme['@primary-color']};
  }
`
export const BorderInput = styled(Input)`
  border-left: solid 5px ${(props) => props.theme['@primary-color']};
  background: ${(props) => props.theme['@gray-0']};
  transition: background 0.5s linear, box-shadow 0.5s cubic-bezier(0.16, 1, 0.3, 1),
    transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  box-shadow: 0 4px 15px 0 rgba(55, 62, 74, 0.14);
  display: flex;
  align-items: stretch;
  height: 60px;
  transform: translateY(0);
  &.ant-input-affix-wrapper-focused,
  &:focus,
  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 5px 15px 0 rgba(55, 62, 74, 0.2);
  }
  * {
    background: ${(props) => props.theme['@gray-0']};
  }
  .ant-input-prefix {
    color: ${(props) => props.theme['@gray-4']};
    margin-right: ${(props) => props.theme['@size-s']};
    margin-left: ${(props) => props.theme['@size-xxs']};
    padding-bottom: 2px;
  }
`

export const BodyLarger = styled(BodyLarge)`
  font-size: ${(props) => props.theme['@size-m']};
`

export const ExtraLarge = styled(BodyLarge)`
  font-size: 175% !important;
  letter-spacing: -0.5px;
  strong {
    color: ${(props) => props.theme['@primary-color']};
  }
`

export const BorderH2 = styled(H2)`
  padding-bottom: ${(props) => props.theme['@size-xs']};
  border-bottom: solid 1px ${(props) => props.theme['@border-color-base']};
  margin-bottom: ${(props) => props.theme['@size-l']} !important;
  &:not(:first-child) {
    margin-top: ${(props) => props.theme['@size-xl']} !important;
  }
`
